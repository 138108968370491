import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import haversine from 'haversine-distance';
// import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import './App.css';
import 'leaflet/dist/leaflet.css';
// Import your train icon image (e.g., train-icon.png)
import trainIconImage from './train.png';

// Function to generate coordinates between two points
function generateCoordinates(start, end, interval = 1) {
  const coordinates = [start];
  const distance = haversine(start, end); // Total distance between start and end points
  const numPoints = Math.floor(distance / interval);

  for (let i = 1; i <= numPoints; i++) {
    const lat = start.lat + ((end.lat - start.lat) / numPoints) * i;
    const lng = start.lng + ((end.lng - start.lng) / numPoints) * i;
    coordinates.push({ lat, lng });
  }

  coordinates.push(end); // Ensure the end coordinate is included
  return coordinates;
}

function App() {
  const bangalore = { lat: 12.9716, lng: 77.5946 };
  const mysore = { lat: 12.2958, lng: 76.6394 };
  const coordinates = generateCoordinates(mysore, bangalore, 1); // 1-km interval coordinates

  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [location, setLocation] = useState({ lat: 12.9716, lng: 77.5946 }); // Default location (e.g., Bangalore)
  const [trainData, setTrainData] = useState({
    speed: 0,
    brakePressure: 0,
    throttle: 0,
    speedChangeRate: 0,
    brakeChangeRate: 0,
    throttleChangeRate: 0,
    trackConditions: {
      gradient: 'unknown',
      curve: 'unknown',
    },
    signalState: 'unknown',
    currentSpeed: 0,
    maxSpeed: 0,
    keyPressed: 'None',
  });
  const [error, setError] = useState(null);
   // Coordinates for Bangalore and Mysore
   const trackCoordinates = [bangalore, mysore];
 
   // Calculate the midpoint for centering the map
   const centerLat = (bangalore.lat + mysore.lat) / 2;
   const centerLng = (bangalore.lng + mysore.lng) / 2;
   const mapCenter = { lat: centerLat, lng: centerLng };
   // Create a custom train icon
  const trainIcon = new L.Icon({
    iconUrl: trainIconImage,
    iconSize: [32, 32], // Adjust size as needed
    iconAnchor: [16, 16], // Center the icon
    popupAnchor: [0, -16], // Position the popup above the icon
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeElapsedInHours = (currentTime - lastUpdateTime) / (1000 * 3600); // Convert ms to hours
      const distanceCovered = trainData.currentSpeed * timeElapsedInHours; // Distance in km

      if (distanceCovered > 0) {
        setCurrentPositionIndex((prevIndex) => {
          const newIndex = prevIndex + Math.floor(distanceCovered);
          return newIndex < coordinates.length ? newIndex : coordinates.length - 1;
        });
      }
    }, 2000);

    const socket = io('https://control-software.aulas.in'); // Replace with your server URL
    // const socket = io('http://localhost:3001'); // Replace with your server URL
    socket.onopen = function() {
      console.log('WebSocket connection established');
    };
    socket.onerror = function(error) {
        console.error('WebSocket Error:', error);
    };
    // socket.on('dataUpdated', (data) => {
    //     console.log('Data updated on the server:', data);
    // });
    // Listen for the "dataUpdated" event and update state with the received data
    socket.on('dataUpdated', (data) => {
      console.log('Data received:', data);
      setTrainData({
        currentSpeed: data.currentSpeed ?? 0,
        maxSpeed: data.maxSpeed ?? 0,
        throttle: data.throttle ?? 0,
        brakePressure: data.brakePressure ?? 0,
        keyPressed: data.keyPressed ?? 'None',
      });
    });

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, [trainData, lastUpdateTime, coordinates]);

    

    // Clean up the socket connection on component unmount
    // const fetchData = async () => {
    //   try {
    //     // const trainID = 'your-train-id'; // Replace with actual train ID
    //     const response = await axios.get(`http://localhost:3001/scene/update`);
    //     // const response = await axios.get(`https://locomotive.aulas.in/api/simulation/real-time-data/${trainID}`);
    //     const data = response.data;

    //     // setLocation({ lat: data.lat, lng: data.lng });
    //     // setTrainData({
    //     //   speed: data.speed ?? 0,
    //     //   brakePressure: data.brakePressure ?? 0,
    //     //   throttle: data.throttle ?? 0,
    //     //   speedChangeRate: data.speedChangeRate ?? 0,
    //     //   brakeChangeRate: data.brakeChangeRate ?? 0,
    //     //   throttleChangeRate: data.throttleChangeRate ?? 0,
    //     // });
    //     // Extract position and speed from the API response
    //     setLocation({ lat: data.trainPosition.x, lng: data.trainPosition.y });
    //     setTrainData({
    //       speed: data.speed ?? 0,
    //       brakeStatus: data.brakeStatus ?? 'unknown',
    //       signalState: data.signalState ?? 'unknown',
    //       trackConditions: {
    //         gradient: data.trackConditions.gradient ?? 'unknown',
    //         curve: data.trackConditions.curve ?? 'unknown',
    //       },
    //       brakeStatus: 'unknown',
    //       signalState: 'unknown',
    //     });
    //     setError(null);
    //   } catch (err) {
    //     console.error('Error fetching train data:', err);
    //     setError('Failed to fetch train data. Displaying default values.');
    //     setTrainData({
    //       speed: 0,
    //       brakePressure: 0,
    //       throttle: 0,
    //       speedChangeRate: 0,
    //       brakeChangeRate: 0,
    //       throttleChangeRate: 0,
    //       trackConditions: {
    //         gradient: 'unknown',
    //         curve: 'unknown',
    //       },
    //       brakeStatus: 'unknown',
    //       signalState: 'unknown',
    //     });
    //   }
    // };

    // fetchData();
    // const interval = setInterval(fetchData, 2000);
    // return () => clearInterval(interval);
  // }, []);

  return (
    <div className="app-container">
      {error && <div className="error-message">{error}</div>}
      <MapContainer center={coordinates[0]} zoom={9} className="map-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
         <Polyline positions={coordinates} color="blue" weight={4} />
         <Marker position={coordinates[currentPositionIndex]} icon={trainIcon}>
          <Popup>
            <div>
              <p><strong>Current Speed:</strong> {trainData.currentSpeed} km/h</p>
              <p><strong>Brake Status:</strong> {trainData.brakeStatus}</p>
              <p><strong>Signal State:</strong> {trainData.signalState}</p>
              {/* <p><strong>Gradient:</strong> {trainData.trackConditions.gradient}</p>
              <p><strong>Curve:</strong> {trainData.trackConditions.curve}</p> */}
              <p><strong>Coordinates</strong> {location.lat}</p>
            </div>
          </Popup>
        </Marker>
      </MapContainer>

      <div className="overlay">
        <div className="speedometers left">
          <Gauge label="Speed" value={trainData.currentSpeed} maxValue={300} />
          <Gauge label="Brake Pressure" value={trainData.brakePressure} maxValue={100} />
          <Gauge label="Throttle" value={trainData.throttle} maxValue={100} />
        </div>
        <div className="speedometers right">
          <Gauge label="Speed Change Rate" value={trainData.speedChangeRate} maxValue={50} />
          <Gauge label="Brake Change Rate" value={trainData.brakeChangeRate} maxValue={50} />
          <Gauge label="Throttle Change Rate" value={trainData.throttleChangeRate} maxValue={50} />
        </div>
      </div>
    </div>
  );
}

function Gauge({ label, value, maxValue }) {
  // const rotation = (value / maxValue) * 180; // Adjust for rotation angle
  // Calculate rotation: start at 90 degrees (pointing down) and move counterclockwise to -90 degrees
  const rotation = 90 + (value / maxValue) * 180; // Adjust to rotate from bottom (90) to left (-90)

  return (
    <div className="gauge">
      <div className="gauge-label">{label}</div>
      <div className="needle" style={{ transform: `rotate(${rotation - 90}deg)` }}></div>
      <div className="value-label">{value}</div>
    </div>
  );
}

export default App;
